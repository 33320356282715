<template>
<div>
<Image :path="path"> </Image>
</div>
</template>

<script>

import Image from "../../components/chart/Image.vue";

export default {
  name: 'ImageView',
  components:  { Image } ,
  data : function() {
      return { 
        path: ""
      }
  },
  async created( ) {

       this.path = window.location.href;
    }
}

</script>
<style>
header,nav{
display: none !important;
}

main{
width: 100% !important ;
}

.container {
  width: 200px;
  margin: 30px;
}

.input {
  width: 100%;
  margin: 0px;
  padding-right: 5px;
}

.eye::after {
  font-family: 'FontAwesome';
  content: "\f06e";
}

.eye-slash::after {
  font-family: 'FontAwesome';
  content: "\f070";
}

.input-icon {
  position: relative;
  float: right;
  margin-top: -25px;
}
</style>